// MainPage.js
import React from 'react';


const MainPage = () => {
  return (
    <div>
      {/* <h1>Welcome to the Main Page</h1> */}
      {/* Other content and components */}
    </div>
  );
};

export default MainPage;
